// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assembly-js": () => import("../src/pages/assembly.js" /* webpackChunkName: "component---src-pages-assembly-js" */),
  "component---src-pages-engineering-js": () => import("../src/pages/engineering.js" /* webpackChunkName: "component---src-pages-engineering-js" */),
  "component---src-pages-fc-js": () => import("../src/pages/fc.js" /* webpackChunkName: "component---src-pages-fc-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logistics-js": () => import("../src/pages/logistics.js" /* webpackChunkName: "component---src-pages-logistics-js" */),
  "component---src-pages-manufacturing-solutions-js": () => import("../src/pages/manufacturing-solutions.js" /* webpackChunkName: "component---src-pages-manufacturing-solutions-js" */),
  "component---src-pages-mechanical-design-js": () => import("../src/pages/mechanical-design.js" /* webpackChunkName: "component---src-pages-mechanical-design-js" */),
  "component---src-pages-supply-chain-management-js": () => import("../src/pages/supply-chain-management.js" /* webpackChunkName: "component---src-pages-supply-chain-management-js" */)
}

